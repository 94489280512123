<template>
    <cluji-card>
        <template #title>
            Mailing-list
            <a href="javascript:void(0)" class="ml-3 text-danger" v-if="isWebcretaire" @click="sendTestMail">
                Envoyer un mail de test aux webcrétaires
            </a>
        </template>
        <div class="text-center">
            <b-button variant="info" size="lg" @click="triggerHelpModal">
                <font-awesome-icon icon="question-circle"/>
                Mode d'emploi
                <font-awesome-icon icon="question-circle"/>
            </b-button>
        </div>
        <b-row>
            <b-col xl="8" offset-xl="2" lg="10" offset-lg="1">
                <b-form @submit.prevent="sendMail">
                    <b-form-group label="Sujet">
                        <b-form-input
                            v-model="form.subject"
                            type="text"
                            required
                        />
                    </b-form-group>
                    <b-form-group label="Destinataires">
                        <b-row>
                            <b-col cols="10">
                                <multi-select
                                    v-model="form.to"
                                    :options="mailingLists"
                                    :multiple="false"
                                    :close-on-select="true"
                                    track-by="value"
                                    :clear-on-select="true"
                                    :preserve-search="false"
                                    label="text"
                                />
                            </b-col>
                            <b-col cols="2" class="pl-0">
                                <b-button
                                    class="w-100 pl-0 pr-0 text-center"
                                    @click="displayEmails"
                                    v-b-tooltip.hover="
                                        'Afficher les addresses mail'
                                    "
                                    variant="outline-secondary"
                                >
                                    <font-awesome-icon
                                        :icon="['far', 'envelope']"
                                    />
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form-group>
                    <b-form-group label="Titre">
                        <b-form-input
                            v-model="form.title"
                            type="text"
                            required
                        />
                    </b-form-group>
                    <b-form-group label="Corps">
                        <balloon-editor v-model="form.body" />
                    </b-form-group>
                    <div class="text-center">
                        <b-button type="submit" variant="success">
                            <font-awesome-icon icon="paper-plane" />
                            Envoyer
                        </b-button>
                    </div>
                </b-form>
            </b-col>
        </b-row>
    </cluji-card>
</template>

<script>
    import { apiPath } from "@/util/http";
    import alert from "@/util/alert";
    import { isWebcretaire } from "@/util/auth";

    const DisplayMailAdressesModal = () => import('@/components/modals/mailer/DisplayMailAdressesModal');
    const MailerHelpModal          = () => import('@/components/modals/mailer/MailerHelpModal');
    const MultiSelect              = () => import('@/components/MultiSelect');
    const BalloonEditor            = () => import('@/components/BalloonEditor');
    const ClujiCard                = () => import('@/components/ClujiCard');

    export default {
        name: "AdminMailer",
        components: { MultiSelect, BalloonEditor, ClujiCard },
        data: () => ({
            mailingLists: [
                {
                    value: "ROLE_USER",
                    text: "Utilisateurs valides"
                },
                {
                    value: "ROLE_CLUJI",
                    text: "Clujistes"
                },
                {
                    value: "ROLE_ASSO",
                    text: "Associations extérieures"
                },
                {
                    value: "ROLE_ANY_ASSO",
                    text: "Cluji + autres assos"
                }
            ],
            form: {
                subject: null,
                to: null,
                title: null,
                body: null
            }
        }),
        computed: {
            isWebcretaire
        },
        methods: {
            triggerHelpModal() {
                this.$store.dispatch('modal/create', {
                    component: MailerHelpModal,
                    props: {}
                });
            },
            displayEmails() {
                if (!this.form.to) {
                    this.$toaster.warning('Sélectionne une catégorie de personnes avant de cliquer sur ce bouton...');
                    return;
                }

                this.$store.dispatch('modal/create', {
                    component: DisplayMailAdressesModal,
                    props: {userType: this.form.to}
                });
            },
            sendMail() {
                alert.loading();
                this.axios.post(apiPath('admin_send_mail'), {...this.form, to: this.form.to.value})
                    .then(() => this.$toaster.success('Mail envoyé'))
                    .catch(() => this.$toaster.error("Impossible d'envoyer l'email"))
                    .finally(alert.stopLoading);
            },
            sendTestMail() {
                alert.loading();
                this.axios.post(apiPath('admin_send_test_mail'))
                    .then(() => this.$toaster.success('Mail de test envoyé'))
                    .catch(() => this.$toaster.error("Impossible d'envoyer l'email de test"))
                    .finally(alert.stopLoading);
            }
        }
    }
</script>
